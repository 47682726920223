.section {
    padding-top: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 80%;
    height: 50vh;
    height: auto;
    margin: 0;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    gap: 0;
    z-index: 5;
    /* background-color: orange; */
    position: fixed;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.form button {
    border: none;
    background-color: #0c4c6b;
    color: #fff;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 0.6em 0;
    font-size: 14px;
    font-size: 0, 875rem;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
}

.imgCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.imgCont img {
    width: 100%;
}

.iconCont {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
}

.titleCont {
    text-align: center;
    width: 4000%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    gap: 5px;
    font-size: 14px;
    font-size: 0, 875rem;
    font-weight: bold;
    text-transform: uppercase;
}

.subtitulo {
    width: 100%;
    font-size: 10px;
    font-weight: 500;
}

.inputMaterial {
    width: 100%;
}

/* .form span {
    font-size: 14px;
    font-size: 0, 875rem;
    font-weight: bold;
    text-transform: uppercase;
} */

.back {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.inputContWeb {
    display: none;
}

.inputContMobile {
    display: flex;
    flex-direction: column;
    gap: 1em;
    gap: 10px;
    width: 100%;
}

.descripcion {
    width: 100%;
}


.registrar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: green; */
    gap: 1em;
}

.registrar span {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 500;
}

.registrar a {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: bold;
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: #0c4c6b;
}

.terminos {
    margin-top: 10px;
    border-top: 1px solid #707070;
    width: 80%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 0.625rem;

}

.terminos span {
    color: #0c4c6b;
}

.selectMoto {
    width: 100%;
    /* background-color: red; */
}

.btnCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1em;
    font-weight: bold;
    padding-bottom: 0.5em;
}

.btnCont button {
    color: #142848;
    border: 0.5px solid #142848;
    background-color: #FFFFFF;
    width: 100%;
}

.btnCont button:hover {
    color: #FFFFFF;
    background-color: #142848;
}

.enviar {
    background-color: #142848;
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enviar button {
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    background-color: #142848;
}

.enviar:hover {
    opacity: 0.9;
}

.descripcionMobile {
    display: block;
    width: 100%;
}

.descripcionWeb {
    display: none;
}

.MuiInputLabel-root {
    font-size: 25px !important;
    padding-bottom: 20px !important;
  }
  
  .MuiInput-root {
    height: 45px !important;
  }

@media (min-width: 768px) {
    .section {
        padding-top: 1em;
        top: 55%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-height: 98vh;
    }

    .form {
        gap: 1.5em;
        gap: 10px;
    }

    .inputWeb {
        display: block;
    }

    .inputMobile {
        display: none;
    }

   

    .back span {
        font-weight: bold;
    }

    .titleCont {
        font-size: 20px;
        width: 150%;
        gap: 10px;

    }

    .subtitulo {
        font-size: 15px;
        font-size: 12px;
    }

    .inputContMobile {
        display: none;
    }

    .inputContWeb {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
    }


    .selectMoto p {
        font-size: 21px;
        font-size: 0, 5526315789473685rem;
        font-size: 15px;
        font-size: 12px;

    }

    .btnCont button {
        font-size: 30px;
        font-size: 0, 78947368421052635rem;
        font-weight: bold;
        font-size: 15px;
        font-size: 12px;

    }

    .enviar button {
        font-size: 12px;
    }

    .terminos {
        font-size: 12px;
        margin-top: 1em;
    }

    .descripcionMobile {
        display: none;
    }

    .descripcionWeb {
        display: block;
        width: 100%;

    }

    .icon {
        font-size: 4em;
        cursor: pointer;
    }

    .fontsize {
        font-size: 15px;
    }

}

@media (min-width: 1900px) {
    .section {
        padding:  2em 0;
    }
    .titleCont {
        font-size: 25px;
    }

    .subtitulo {
        font-size: 15px;
    }

    .selectMoto p {
        font-size: 15px;
    }

    .btnCont button {
        font-size: 15px;
    }

    .enviar button {
        font-size: 15px;

    }

    .terminos {
        font-size: 15px;
    }

    .icon {
        font-size: 4em;
    }

    .fontsize {
        font-size: 15px;
    }
}