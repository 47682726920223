.section {
  margin: 1em 0;
  background-color: black;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0.2px 10px rgb(211, 204, 204), 0 -0.2px 10px rgb(211, 204, 204);
}
.contenedor {
  width: 90%;
  /* background-color: orange; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contenedor span {
  width: 50%;
  /* background-color: orange; */
}
.back {
  width: 50%;
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  /* gap: 0.3em; */
}
.back span {
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  line-height: 30px;
}
.icon {
  color: #fff;
}
.imgContainer {
  /* background-color: aqua; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center   ;
}
.imgContainer img {
  max-width: 100%;
  object-fit: cover;
}


@media (min-width: 768px) {

}
