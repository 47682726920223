.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    padding-bottom: 1em;
    border-bottom: 2px solid #0c4c6b;
    width: 100%;
    cursor: pointer;
}

.card h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.info {
    font-size: 16px;
    padding-left: 0.5em;
    display: flex;
    align-items: center;
    gap: 1em;
}

@media (min-width: 768px) {

    .card {
        width: 100%;
    }

    .card h3 {
        font-size: 15px;
    }

    .info {
        font-size: 16px;
        padding: 0;
    }
}

@media (min-width: 1900px) {
    .card h3 {
        padding-top: 5px;
    }
}