.section {
  padding: 1em 0 2em 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {  
  width: 80%;
}

.container h3 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.125rem;
  color: #df0404;
}

.grid {
    padding-top: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
.card {
    /* background-color: red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30vh;
    gap: 0.7em 0;
}
.imgContainer {
    /* height: 50vh; */
    width: 100%;
    height: 100%;
}
.imgContainer img{
    width: 100%;
    height: 100%;
    /* max-height: 100%; */
    object-fit: cover;
}
.card button {
    /* height: 0; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.75rem;
    width: 60%;
    background-color: #df0404;
    color: #fff;
    border: none;
    padding: 0.6em 0;
}

@media (min-width: 768px) {
  .section {
    padding: 2em 0 5em 0;
  }
  .container h3 {
    padding: 1em 0;
  }
  .grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card {
    width: 22%;
    height: 60vh;
    gap: 2em;
  }
  .card button {
    padding: 0.4em 0;
  }
  
}