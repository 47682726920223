.section {
    position: relative;
    /* height: 25vh; */
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EDF0F2;
}

.imgContainer {
    /* height: 50vh; */
    width: 100%;
    height: 100%;
}
.imgContainer img{
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.texto { 
    padding: 1em 0 0 0;
    width: 90%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.texto h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 500;
}
.texto h3 {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.31rem;
    color: red;
}
.texto span {
    padding: 1.5em 0;
    font-size: 0.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;

}

@media (min-width: 768px) {
    .section {
        padding-bottom: 4em;
    }
    .imgContainer {
    /* height: 50vh; */
    width: 100%;
    height: 30vh;
}
.texto h4 {
    font-size: 32px;
    font-weight: bold;
    color: #DF0404;
}
.texto h3 {
    color: #000000;
}
.texto span {
    padding: 1em 0;
    font-size: 21px;
    font-weight: 100;
}
}