.section {
  background-color: #edf0f2;
  padding-top: 1em;
  /* display: flex; */
  /* align-items: center;
    justify-content: center;  */
}
.textCont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.categorias {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.625rem;
  /* padding-left: 12px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  gap: 1em;
}
.categorias span {
  cursor: pointer;
}
.motoresContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.borde {
  width: 90%;

  margin: 0;
  padding: 0;
  background-color: rgba(112, 112, 112, 0.052);
  /* border: 5px solid #fff; */
  border: 12px solid #edf0f2;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.imgContainer {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  width: 30%;
}
.imgContainer img {
  object-fit: cover;
  width: 60%;
}
.MotoresInfo {
  /* background-color: orange; */
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.MotoresInfo h4 {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.3125rem;
}
.MotoresInfo span {
  font-size: 0.5rem;
}
.MotoresInfo button {
  font-size: 0.75rem;
  font-weight: bold;
  background-color: #df0404;
  border: none;
  color: #fff;
  padding: 0.7em 0;
  width: 35%;
  margin-top: 1em;
}
.informacionCont {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  width: 100%;
}
.informacion {
  width: 90%;
  padding: 0 0 1em 0;

  /* display: flex;
    flex-direction: column;
    gap: 1em; */
  /* align-items: center; */
  /* justify-content: center; */
}
.informacion h3 {
  padding: 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
  color: #df0404;
  font-size: 1.3125rem;
  /* margin: 0; */
}
.informacion h4 {
  margin: 0;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;
}
.informacion ul {
  font-weight: 500;
  font-size: 0.625rem;

  margin: 0;
  padding: 0.5em 0;
}
.informacion ul li {
  margin: 0.2em 0;
  /* background-color: red; */
  padding: 0;
  list-style: none;
}
.informacion p {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;
}
.informacion span {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;
  color: #df0404;
}
@media (min-width: 768px) {
  .section {
    padding-top: 3em 0;
  }
  .textCont {
    padding-bottom: 2em;
  }
  .motoresContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 2em;
  }
  .componentCont {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .container {
    width: 90%;
    padding: 3em 0;
  }
  .test {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
  }
  .borde {
    border: none;
    gap: 2em;
    height: 23vh;
    /* padding: 2em 0; */
  }
  .MotoresInfo {
    gap: 1em;
  }
  .MotoresInfo h4 {
    font-size: 0.842rem;
  }
  .MotoresInfo span {
    font-size: 0.47rem;
    padding: 0;
    text-align: start;
  }
  .MotoresInfo button {
    font-size: 0.63rem;
    width: 60%;
    margin-top: 0;
  }
  .imgContainer {
    height: 100%;
  }
  .imgContainer img {
    width: 100%;
  }
}
