.section {
    position: relative;
    /* height: 25vh; */
    /* margin-top: 1em; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EDF0F2;
}

.imgContainer {
    width: 100%;
}

.imgContainer img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.texto {
    padding: 1em 0 0 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.texto h3 {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.62rem;
    color: #0c4c6b;
}

.texto span {
    padding: 1.5em 0;
    font-size: 0.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}

.mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tienda {
    width: 90%;
}

.tiendaTitle {
    text-transform: uppercase;
    font-weight: 500;
    padding: 1em 0;
    font-size: 18px;
    /* padding-bottom: 1.5em; */
}

.inputCont {
    margin: 0.6em 0;
    display: flex;
    align-items: flex-start;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    padding: 0.4em 0;
    background-color: #fff;
    border: 1px solid #6E6E6E;
}

.icon {
    padding-left: 1em;
}

input:focus,
input:active {
    outline: none;
    /* Elimina el contorno al recibir foco */
    /* Añade otros estilos personalizados para el input cuando es clickeado */
}

.inputCont input {
    border: none;
    padding-bottom: 2%;
    width: 100%;

}

.inputCont input::placeholder {
    border: none;
    font-size: 0.5rem;
}

.zonasBtn {
    border: none;
    background-color: #0c4c6b;
    color: #FFFFFF;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 10px;
    width: 60%;
    padding: 0.5em 0;
    font-weight: bold;
    cursor: pointer;
}

.zonasBtn:hover {
    opacity: 0.9;
}

.map {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 90vh; */
    gap: 1em;
    padding-bottom: 2em;

}

.infiniteScroll {
    width: 100%;
}

.botones {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}

.botones button {
    border: none;
    background-color: #0c4c6b;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    background-color: #FFFFFF;
    border: none;
    color: #0c4c6b;
    padding: 0.7em 0;
    width: 35%;
    margin-top: 1em;
    cursor: pointer;
}

.web {
    display: none;
}

.filtro {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
}

.navSpace {
    height: 25vh;
    padding: 1em 0;
}

.navSpaceVercel {
    height: 15vh;
    padding: 1em 0;
}


@media (min-width: 768px) {
    .navSpace {
        height: 10vh;
        padding: 1em 0;
        display: none;
    }

    .navSpaceVercel {
        height: 10vh;
        padding: 1em 0;
        display: none;
    }

    .mobile {
        display: none;
    }

    .imgContainer {
        height: 40vh;
    }

    .texto h3 {
        font-size: 32px;
    }

    .texto span {
        font-size: 24px;
    }

    .tiendaTitle {
        font-size: 32px;
        padding: 0.5em 0;
    }

    .inputCont input::placeholder {
        font-size: 21px;
    }

    .inputCont input {
        /* padding-bottom: 1%; */
        padding: 1em 0;
        padding-left: 1em;
    }

    .icon {
        font-size: 40px;
    }

    .web {
        padding-bottom: 5em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .tiendaContainer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        gap: 1rem;
    }

    .concesionarioInfo {
        width: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        justify-content: flex-start;
        gap: 20px;
    }

    .concesionarioInfo div {
        width: 100%;
    }

    .map {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 1em;
        padding: 0;
        margin: 0;
    }

    .infinite-scroll-component__outerdiv {
        width: 90%;
    }

    .zonasBtn {
        font-size: 15px;
        padding: 0.5em 0;
    }

    .infinite {
        width: 100%;
    }

    .botones {
        width: 90%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1em;
        padding: 0;
        margin: 0;
    }

    .inputCont {
        margin: 0;
    }

    .botones button {
        font-size: 24px;
        margin: 0;
    }

}

@media (min-width: 1500px) {

    .concesionarioInfo {
        width: 53%;
    }

    .zonasBtn {
        font-size: 20px;
    }
}