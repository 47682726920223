.section {
    background-color: #EDF0F2;
    padding-top: 1em;
    /* display: flex; */
    /* align-items: center;
    justify-content: center;  */
}

.categorias {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.625rem;
    /* background-color: red; */
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-weight: bold;
    gap: 1em;
    cursor: pointer;
}

.borde {
    margin: 0;
    padding: 0;
    background-color: #fff;
    /* border: 5px solid #fff; */
    border: 12px solid #EDF0F2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.borde span {
    font-size: 0.563rem;
}
.imgContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (min-width: 768px) {
    .section {
        /* background-color: orange; */
        display: flex;
        flex-direction: column;
        /* background-color: blue; */
    }
    .categorias {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* background-color: blue; */
        width: 80%;
        gap: 2.5em;
    }
    .categorias {
        font-weight: 500;
        font-size: 0.632rem;
    }
}