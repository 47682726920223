.section {
    position: relative;
    background-color: #000000;
    padding-bottom: 3em;
}
 div {
    outline: none;
}

.filtro {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
}
.container {
    position: relative;
}
.absolute {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}
.absoluteR {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
}

.flecha {
    font-size: 5em;
}
.section h3 {
    padding: 1.5em 0;
    text-align: center;
    text-transform: uppercase;
    color: #6EC1E4;
    font-weight: bold;
    font-size: 0.632rem;
}
.slider {
    width: 100%;
    /* height: 0%; */
}
.slide {
    /* height: 90%; */
    /* background-color: red; */
    width: 100%;
}
.slide img {
    height: 100%;
    width: 90%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .flecha {
        font-size: 2em;
    }

}