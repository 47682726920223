.section {
    padding-top: 3em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 80%;
    height: 50vh;
    height: auto;
    margin: 0;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    z-index: 5;
    /* background-color: orange; */


    position: fixed;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.form button {
    border: none;
    background-color: #0c4c6b;
    color: #fff;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    padding: 0.6em 0;
    font-size: 14px;
    font-size: 0, 875rem;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
}

.imgCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.imgCont img {
    width: 100%;
}

.iconCont {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: start;
    align-items: flex-start;
}


.titleCont {
    text-align: center;
    width: 4000%;


    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-size: 14px;
    font-size: 0, 875rem;
    font-weight: bold;
    text-transform: uppercase;
}

.subtitulo {
    font-size: 10px;
    font-weight: 500;
}

.inputMaterial {
    width: 100%;
}

.form span {
    /* font-size: 14px;
    font-size: 0, 875rem; */
    font-weight: bold;
    text-transform: uppercase;
}

.back {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.inputContWeb {
    display: none;
}

.inputContMobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* gap: 1em; */
    width: 100%;
}

/* .inputCont {
    display: flex;
    flex-direction: column;
    gap: 1em;
} */
.registrar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: green; */
    gap: 1em;
}

.registrar span {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 500;
}

.registrar a {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: bold;
    text-decoration: none;
    padding: 0;
    margin: 0;
    color: #0c4c6b;
}

.terminos {
    margin-top: 10px;
    border-top: 1px solid #707070;
    width: 80%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 10px;
    font-size: 0.625rem;

}

.terminos span {
    color: #0c4c6b;
}

.selectMoto {
    width: 100%;
}
.selectMoto p {
    margin-top: 0;
}
.btnCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1em;
    font-weight: bold;
    padding-bottom: 0.5em;
}

.btnCont button {
    color: #142848;
    border: 0.5px solid #142848;
    background-color: #FFFFFF;
    width: 100%;
}

.btnCont button:hover {
    color: #FFFFFF;
    background-color: #142848;
}

.enviar {
    background-color: #142848;
    width: 100%;
    text-align: center;
    padding: 0.5em 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.enviar button {
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    background-color: #142848;
}

.enviar:hover {
    opacity: 0.9;
}

/* @media (min-width: 768px) and (max-width: 1900px) { */
    @media (min-width: 768px) {
    .section {
        padding-top: 1em;
        top: 55%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-height: 98vh;
    }

    .form {
        gap: 1.5em;
    }

    .inputWeb {
        display: block;
    }

    .inputMobile {
        display: none;
    }
    .titleCont {
        font-size: 20px;
        gap: 1em;
        width: 100%

    }
    
    .subtitulo {
        font-size: 12px;
    }
    .back span {
        font-weight: bold;
        /* font-size: 20px; */
    }

    .inputContMobile {
        display: none;
    }

    .inputContWeb {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        justify-content: space-between;
        width: 100%;
    }

   
    .selectMoto p {
        font-size: 15px;
        font-size: 12px;
    }

    .btnCont button {
        font-weight: bold;
        font-size: 15px;
        font-size: 12px;
    }

    .enviar button {
        font-size: 30px;
        font-size: 1, 875rem;
        font-size: 0, 78947368421052635rem;
        font-size: 15px;
        font-size: 12px;

    }

    .terminos {
        font-size: 12px;
    }

    .icon {
        font-size: 4em;
        cursor: pointer;
    }
    .fontsize {
        font-size: 16.8px;
        font-size: 5px;
    }
}

@media (min-width: 1900px)  {
    .section {
        padding:  2em 0;
    }

    .form {
        gap: 1.5em;
    }


    .titleCont {
        font-size: 25px;
        gap: 1em;
        width: 100%
    }

    .subtitulo {
        font-size: 15px;
    }

    

    .inputContWeb {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        justify-content: space-between;
        width: 100%;
    }

    .selectMoto p {
        font-size: 21px;
        font-size: 0, 5526315789473685rem;
        font-size: 15px;
    }

    .btnCont button {
        font-size: 30px;
        font-size: 0, 78947368421052635rem;
        font-weight: bold;
        font-size: 15px;
    }

    .enviar button {
        font-size: 30px;
        font-size: 1, 875rem;
        font-size: 0, 78947368421052635rem;
        font-size: 15px;

    }

    .terminos {
        font-size: 15px;
    }

    .icon {
        font-size: 4em;
        cursor: pointer;
    }
    .fontsize {
        font-size: 21px;
    }
}