.section {
  background-color: hsl(204, 16%, 94%);
  /* padding-top: 1em; */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 0;
}
.test {
  width: 100%;
  padding: 0;
  /* background-color: #df0404; */
}
.borde {
  height: 20vh;
  width: 100%;
  margin: 0;
  /* padding: 2em 0; */
  background-color: #fff;
  /* border: 5px solid #fff; */
  border: 12px solid #edf0f2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textBtn {
  width: 100%;
}
.texto {
  margin-right: 10px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
  text-align: center;
  padding: 0.3em 0;
  gap: 0.3em;
}

.texto h4 {
  margin: 0;
  padding: 0;
  /* background-color: red; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 0.625rem;
}
.texto span {
  margin: 0;
  font-size: 0.5rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.btn {
  margin-right: 10%;
  border: none;
  background-color: #df0404;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0.6em 0;
  font-size: 0.75rem;
  font-weight: bold;
  width: 70%;
}
.imgContainer {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgContainer img {
  width: 100%;
}

@media (min-width: 768px) {
  .section {
    width: 100%;
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .container {
    width: 90%;
    padding: 0;
  }
  .test {
    width: 100%;
    padding: 1em 0;
  }
  .borde {
    /* background-color: #df0404; */
    height: 30vh;
    width: 95%;
    flex-direction: row;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .imgContainer {
    width: 50%;
  }
  .imgContainer img {
    width: 100%;
    height: 100%;
  }
  .textBtn {
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .texto {
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 1em;
  }
  .texto h4 {
    font-size: 0.842rem;
  }
  .texto span {
    font-size: 0.632rem;
  }
  .btn {
    margin: 1em 0 0 0;
    width: 60%;
    font-size: 0.632rem;
    padding: 0.4em 0;
  }
}
