.section {
  position: relative;
  height: 25vh;
  margin-top: 1em;
}

.imgContainer {
  /* height: 50vh; */
  width: 100%;
  height: 100%;
}
.imgContainer img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.absolute {
  width: 90%;
  left: 0;
  right: 0;
  top: 0;
  height: 98%;
  margin: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  /* background-color: orange; */
}

.absolute h3 {
  margin: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.1rem;
  color: red;
  /* background-color: red; */
}
.absolute h4 {
  margin: 0;
  /* background-color: rebeccapurple; */
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.62rem;
  color: black;
  font-weight: 500;
}
.absolute .inputCont {
  display: flex;
  align-items: flex-start;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  padding: 0.4em 0;
  background-color: #fff;
  border: 1px solid #6e6e6e;
}
.icon {
  padding-left: 1em;
}
input:focus,
input:active {
  outline: none; /* Elimina el contorno al recibir foco */
  /* Añade otros estilos personalizados para el input cuando es clickeado */
}

.inputCont input {
  border: none;
  padding-bottom: 2%;
}
.inputCont input::placeholder {
  border: none;
  font-size: 0.5rem;
}

.absolute span {
  color: #fff;
  font-size: 0.5rem;
  font-family: sans-serif;
}
.absolute button {
  border: none;
  background-color: #df0404;
  color: #fff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 0.6em 0;
  font-size: 0.7rem;
  font-weight: bold;
  width: 30%;
}

@media (min-width: 768px) {
  .section {
    height: 30vh;
  }
  .absolute {
    width: 80%;
  }
  .absolute h3 {
    font-size: 0.842rem;
  }
  .absolute h4 {
    font-size: 0.842rem;
  }
  .absolute span {
    font-size: 0.474rem;
  }
  .absolute button {
    font-size: 0.632rem;
  }

  .inputCont input {
    padding: 1.5em 0 1.5em 1em;
    border: none;
    width: 100%;
  }
  .inputCont input::placeholder {
    border: none;
    font-size: 0.553rem;
  }
  .icon {
    padding-left: 0.3em;
    font-size: 3em;
  }
}
