.nabMobile{
  position: fixed;
  z-index: 500;
  background-color: #fff;

}
.filtro {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000a3;
}
.navbar {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  box-shadow: 0 0.5px 10px black;

}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: blue; */
  width: 90%;
}
.imgContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgContainer img {
  /* width: 50%; */
  max-width: 60%;
  object-fit: cover;
}
.contenedorLupa {
  background-color: black;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  padding: 0.2em;
}
.iconLupa {
  color: #fff;
}

.btnContainer {
  display: flex;
  align-items: center;
  gap: 1em;
  background-color: #EDF0F2;
  padding: 0.5em 0em 0.5em 2em;
}
.btnContainer button {
  background-color: white;
  color: #000000;
  width: 20%;
  border: none;
  font-size: 0.5rem;
  padding: 1em;
}
