/* .navbar {
    position: fixed;
    z-index: 500;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15vh;
    padding: 1em 0;
    box-shadow: 0 0.5px 10px black;
    background-color: #fff;
  }
  .navContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    width: 90%;
  }
  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.alignMenu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
}
.imgContainer {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .imgContainer img {
    cursor: pointer;
    max-width: 50%;
    object-fit: cover;
  }
  .iconContainer {
    width: 100%;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .iconWs {
    width: 100%;
    color: #25d366;
    object-fit: cover;
    height: 100%;
    font-size: 3em;
  
  }
  .contenedorLupa {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 0.2em;
  }
  .iconLupa {
    border-radius: 50%;  
    background-color: #000000;
    color: #fff;
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-size: 0.5em;
  }
  .categorias {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: start;
    gap: 2em;
  }
  .spanCont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2em;
}

  .mercuryContainer {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .mercuryContainer img {
    max-width: 100%;
    object-fit: cover;
  }
  .categorias span {
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.526rem;
  
  }
 */




















 .navbar {
  height: 12vh;
  box-shadow: 0px 2px 4px #6ec1e4;
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}
.navbarNoScroll {
  position: fixed;
  z-index: 100;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 20vh;
  box-shadow: 0 0.5px 10px black;
  background-color: #FFFFFF;
}
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 0.5em 0;
}

.imgContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.imgContainer img {

  max-width: 60%;
  object-fit: cover;
}

.contenedorLupa {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  padding: 0.2em;
}

.iconLupa {
  color: #fff;
}

.navWeb {
  padding: 0.5em 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
}

.mercuryLogo {
  width: 20%;
  cursor: pointer;
}

.mercuryLogo img {
  width: 100%;
}

.secciones {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
}

.secciones span:hover {
  color: #6ec1e4;

  cursor: pointer;
}

.espacio {
  height: 20vh;
}
.espacioScroll {
  height: 12vh;
  background-color: blue;
}
.icon {
  cursor: pointer;
}
.navMobile {
  display: none;
}

.iconWsp {
  font-size: 40px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .filtro {
    z-index: 102;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
  }
  .espacio {
    height: 25vh;
  }
  .navWeb {
    display: none;
  }
  .navbarNoScroll {
    height: auto;
  }
  .navbar {
    height: auto;
  }
  .navMobile {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container {
    padding: 0;
  }


  .sliderContainer {
    width: 100%;
    height: 100%;
    background-color: #EDF0F2;
  }

  .slider {
    padding: 0;
    
  }

  .slider .fondoText {
    font-size: 8px;
    text-align: center;
    width: 90px;
    height: 30.34px;
    padding: 1em 0;
  }
  
    .slider .texto {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #fff;
      width: 90%;
      height: 100%;
      color: #0c4c6b;
    }

    .iconWsp {
      display: none;
    }
}



@media screen and (min-width:768px) and (max-width: 1550px) {
  .secciones span{
    font-size: 12px;
  }
}

@media (min-width: 1500px) {
  .secciones span{
    font-size: 16px;
  }

}
