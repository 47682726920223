.landing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.filtro {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000a3;
}

/* .navSpace{
    height: 15vh;
    padding: 1em 0;
  } */

.header {
    width: 100%;
}

.header img {
    object-fit: cover;
    width: 100%;
}

.menu {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-bottom: 2.2em;
    gap: 1em;
}

.menu h2 {
    color: #000000;
    font-weight: bold;
    font-size: 1.895rem;
    font-size: 65px;
    margin: 0;
}

.motos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
}

.moto {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1em;
    cursor: pointer;
}

.imgContainer {
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 100%;
}

.imgContainer img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
}

.moto button {
    border: none;
    padding: 0.5em 0;
    background-color: #6EC1E4;
    color: #fff;
    width: 50%;
    font-weight: bold;
    font-size: 0.632rem;
    /* box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3), 2px 0px 4px rgba(0, 0, 0, 0.3), -2px 0px 4px rgba(0, 0, 0, 0.3); */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    cursor: pointer
}




@media (max-width: 768px) {
    .navSpaceVercel {
        height: 14vh;
        padding: 1em 0;
    }

    .navSpace {
        height: 22.5vh;
        padding: 1em 0;
    }

    .menu h2 {
        color: #000000;
        font-weight: bold;
        font-size: 1.12rem;
        text-align: center;
    }

    .menu {
        width: 95%;
    }

    .motos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.8em;
        row-gap: 1.5em;
    }

    .moto {
        gap: 0.5em;
    }
}



@media screen and (min-width:768px) and (max-width: 1550px) {
    .menu h2 {
        font-size: 50px;
    }
}