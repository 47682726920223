.imgDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    width: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: center;
    justify-self: center;
}

.imgContainer {
    width: 80%;
}

.imgContainer img {
    object-fit: cover;
    width: 100%;
}

.close {
    padding-left: 1em;
    display: flex;
    align-items: center;
    height: 100%;
}

.icon {
    font-size: 2em;
}

@media (max-width: 768px) {
    .icon {
        font-size: 1em;
    }

    .imgDialog {
        width: 75%;
        flex-direction: column-reverse;
    }

    .close {
        padding-left: 0;
        width: 100%;
        padding-bottom: 1em;
        justify-content: flex-end;
    }

    .imgContainer {
        width: 100%;
    }

}