.section {
    position: relative;
    /* height: 25vh; */
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EDF0F2;
    
}

.imgContainer {
    /* height: 50vh; */
    width: 100%;
    height: 100%;
}
.imgContainer img{
    width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.texto { 
    padding: 1em 0 0 0;
    width: 90%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.texto h3 {
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.62rem;
    color: #DF0404;
}
.texto span {
    padding: 1.5em 0;
    font-size: 0.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
}
.tienda {
    width: 90%;
}
.tienda h3{
    font-size: 0.625rem;
    text-transform: uppercase;
    font-weight: 500;
    /* padding-bottom: 1.5em; */
}

.inputCont {
    margin: 0.6em 0;
    display: flex;
    align-items: flex-start;
    align-items: center;
    justify-content: flex-start;
    width: 70%;
    padding: 0.4em 0;
    background-color: #fff;
    border: 1px solid #6E6E6E;
}

.icon {
    padding-left: 1em;
}
input:focus,
input:active {
  outline: none; /* Elimina el contorno al recibir foco */
  /* Añade otros estilos personalizados para el input cuando es clickeado */
}

.inputCont input {
    border: none;
    padding-bottom: 2%;
}
.inputCont input::placeholder {
    border: none;
    font-size: 0.5rem;
}
.map {
    width: 90%;
}
.botones {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
}
.botones button {
    border: none;
    background-color: #DF0404;
    color: #fff;
    font-size: 0.75rem;
    font-weight: bold;
    background-color: #FFFFFF;
    border: none;
    color: #DF0404;
    padding: 0.7em 0;
    width: 35%;
    margin-top: 1em;
}