.section {
  padding: 0;
  /* width: 100vw; */
  background-color: #000000;
  position: relative;
}
.imgContainer {
  width: 100%;
}
.imgContainer img {
  object-fit: cover;
  width: 100%;
}
.absolute { 
  width: 90%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: violet; */
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}
.absolute h3 {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #6EC1E4;
  font-size: 0.632rem;
}
.info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10%;
  /* background-color: red; */
  height: 100%;
}
.info div {
  /* background-color: green; */
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  font-size: 0.474rem;
  gap: 5em;
}
.colLeft {
  align-items: flex-end;
}
.sectionControl {
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.control {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 60%;
  padding-right: 5em;
  padding-bottom: 5em;
}
.control h3 {
  width: 50%;
  color: #6EC1E4;
  font-weight: bold;
  font-size: 1.105rem;
  text-transform: uppercase;
}
.control span {
  width: 50%;
  color: #FFFFFF;
  font-size: 0.632rem;
}
.imgContainerControl {
  display: flex;
  justify-content: flex-end;
  width: 40%;
}
.imgContainerControl img{
  width: 100%;
}
/* ----------------------------------------
ICONOS */
.caracter {
  position: relative;
  padding: 2em 0;
  background-color: #001A1B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  color: #fff;
  font-size: 0.474rem;
  font-weight: bold;
}
.filtro {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000a3;
}
.caracter h3 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.632rem;
  color: #6EC1E4;
}
.caracter p {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 1em;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  /* background-color: orange; */
}
.columnAlign {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2em;
  /* background-color: gray; */
  width: 100%;
}
.infoCont {
  width: 100%;
  /* background-color: red; */
}
@media (max-width: 768px) {
  .section {
    height: 50vh;
  }
  .imgContainer {
    height: 100%;
  }
  .imgContainer img {
    height: 100%;
  }
  .info {
    gap: 40%;
  }
  .colRight span {
    padding-left: 2em;
  }
  /* -----------------------------
  REVERSA */
  .sectionControl{
    gap: 0;
  }
  .control{
    align-self: center;
    padding: 0 0 0 1em;
    width: 100%;
    /* background-color: orange; */
  }
  .control h3 {
    width: 100%;
  }
  .control span {
    padding: 0.5em 0;
    width: 100%;
  }
  .imgContainerControl {
    width: 100%;
    background-color: #6EC1E4;
  }
  .imgContainerControl img {
    width: 100%;
  }
  /* ------------------------
  EMOJIS */
  .caracter h3 {
    font-size: 1.125rem;
  }
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}