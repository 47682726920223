.section {
    background-color: #ffffff;
    padding-top: 3em;
    /* padding: 3em 0; */
    justify-content: center;
}

.container {
    width: 100%;
    position: relative;
}

.absolute {
    position: absolute;
    left: 0;
    top: 22%;
    transform: translateY(-50%);
    z-index: 10;
    /* transform: translate(-50%, -50%); */
}

.absoluteR {
    position: absolute;
    right: 0;
    top: 22%;
    transform: translateY(-50%);
    /* transform: translate(-50%, -50%); */
    z-index: 10;
}

div {
    outline: none;
}

.texto h5 {
    /* height: 5vh; */
    /* background-color: red; */
    padding: 1em 0 1em 1em;
    margin: 0;
    text-align: start;
    text-transform: uppercase;
    color: #6EC1E4;
    font-weight: bold;
    font-size: 0.6rem;
}

.texto ul {
    margin: 0;
}
.texto ul:active {
    margin: 0;
}

.texto ul li {
    /* background-color: aqua; */
    padding: 0.2em 0;
    font-size: 0.474rem;
}

.slider {
    padding-left: 0.7em;
    width: 100%;
}

.slide {
    height: 85vh;
    width: 100%;
}

.slide img {
    height: 100%;
    width: 95%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .absolute {
        top: 20%;
    }
    .container {
        height: 30vh;
    }

    .slider {
        padding-left: 0.3em;
        /* height: 100%; */
        /* background-color: red; */
    }

    .absoluteR {
        top: 20%;
    }

    .texto h5 {
        height: 4vh;
        height: auto;
        font-size: 0.55rem;
        width: 95%;
        text-align: start;
        padding-left: 0.5em;
    }
    /* .texto ul {
        margin: 0;
        background-color: blue;
    } */
    .texto ul {
        padding-left: 1em;
        width: 80%;
    }

    li span {
        position: relative;
        left: -3px;
    }


}

@media (min-width: 768px) and (max-width: 1366px) {
    .texto h5 {
        height: 12vh;
    }
}

@media (max-width: 378px) {
    .container {
        height: 35vh;
    }
}