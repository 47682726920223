

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EDF0F2;
    box-shadow: 0 0.2px 10px  rgb(211, 204, 204), 0 -0.2px 10px  rgb(211, 204, 204);
    height: 8vh;
}
.container {
    width: 90%;
    /* margin-left: 10%; */
}

.slider {
    /* background-color: red; */
    width: 90%;
    display: flex;
    /* gap: 5em; */
}
.carruselContainer {
    width: 100%;
    display: flex; 
    /* border: 5px solid yellow; */
    
    align-items: center;
    justify-content: center;
    justify-items: center;
    text-align: center;
}
.borde {
    display: flex; 
    align-items: center;
    justify-content: center;
    justify-items: center;
    font-size: 0.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #fff;
    box-shadow: 0 0.2px 10px  rgb(211, 204, 204), 0 -0.2px 10px  rgb(211, 204, 204);
    width: 90%;
    text-align: center;
    /* padding: 0.05em 0; */
    /* padding: 0.25px 0; */
    margin: 0 auto;
    color: #DF0404;
    height: 50px;
    /* border: 5px solid green; */
    /* background-color: green; */
}

/* .slider > div {
  margin: 0 10px;
} */
/* .carruselContainer {
  margin: 0 -10px;
} */