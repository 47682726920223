/* .markerSection {
    position: relative;
}

.markerSection img {
    z-index: 1;
}

.markerSection .marcador {
    z-index: 100;
} */

 .marcador {
    background-color: white;
    width: 300px;
    /* position: absolute; */
    border-radius: 5%;
    /* z-index: 5000000; */
    /* right: 100px; */
    margin-right: 100px;
}

.marcadorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.5em;
    gap: 0;
    padding: 1em 0;
}

.titleCont {
    display: flex;
    gap: 0;
}


.marcador h3 {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 0;
    margin: 0;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    width: 100%;
    padding: 0 10px;
}


.marcador div {
    font-size: 20px;
    width: 100%;
}

.marcador div span {
    width: 100%;
    padding-left: 0.5em;
    font-size: 13px;
}

.icon {
    font-size: 13px;
    padding-left: 10px;

}
/* 
@media screen and (min-width:768px) {
    .marcador h3 {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        width: 100%;
    }

    .marcador div span {

    }

} */